import { Link } from "gatsby"
import styled from "styled-components";

const BlogCard = styled.div`
    display: flex;
    flex-flow: row wrap;
    border: solid #707070 thin;
    margin-bottom: 35px;
    width: 32%;
    align-self: flex-start;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const BlogImg = styled.img`
    margin: 0;
    min-width: 300px;
    object-fit: cover;
    min-width: 100%;
    height: 200px;
    @media (max-width: 768px) {
        min-width: 175px;
        width: -webkit-fill-available;
    }
    @media (max-width: 426px) {
        min-width: 100px;
    }
`;

const BlogExcerpt = styled.div`
    font-size: 18px;
    place-self: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    @media (max-width: 768px) {
        font-size: 14px;
        -webkit-line-clamp: 3;
        margin-bottom: -2px;
    }
    
`;

const Goto = styled(Link)`
    text-decoration: none;
    color: #000;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 30px;
    margin-right: 20px;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        display: none;
        margin: 0;
    }
`;

const Arrow = styled.img`
    margin: 0;
    transform: rotate(-90deg);
    width: 20px;
    height: fit-content;
    place-self: flex-end;
    padding-left: 4px;
    padding-bottom: 5px;
    @media (max-width: 768px) {
        display: none;
    }
`;

const BlogInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-content: center;
    text-align: left;
    margin: 15px;
`;

const BlogTitle = styled.div`
    font-size: 26px;
    font-weight: 700;
    color: #707070;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 55px;
    @media (max-width: 1220px) {
        height: 78px;
    }
    @media (max-width: 1140px) {
        height: 104px;
    }
    @media (max-width: 768px) {
        font-size: 22px;
        height: 45px;
    }
`;

const ReadMore = styled(Link)`
    display: flex;
    justify-items: center;
    align-content: center;
    font-size: 14px;
    margin: 0;
    color: #707070;
    align-self: flex-end;
    padding-right: 8px;
    margin-top: 5px;
    margin-bottom: -5px;
    text-decoration: none;
    @media (min-width: 768px) {
        display: none;
    }
`;

export {
    BlogCard,
    BlogExcerpt,
    BlogImg,
    Goto,
    Arrow,
    BlogInfo,
    BlogTitle,
    ReadMore
}