import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Top, Title, Dot, Content} from "../styles/processStyles"
import {BlogCard, BlogExcerpt, BlogImg, Arrow, Goto, BlogInfo, BlogTitle, ReadMore} from "../styles/blogsStyle"
import {getImage} from "../helpers";
import { graphql } from 'gatsby'
import ArrowImg from "../images/arrow.png"
import ContactForm from "../components/contact"

export default class Blogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false
    }
  }
  
render() {
  return (
      <Layout>
        <Seo title="Our Blogs" />
        <Top>
          <Title>OUR BLOGS</Title>
          <div><Dot/><Dot/><Dot/></div>
        </Top>
        <Content>
          {this.props.data.allNodeArticle.edges.map(({ node }) => {
                    let photo = getImage(node);
                    return (
                      <BlogCard key={node.title}>
                        <BlogImg src={photo.url} alt={photo.alt}/>
                        <BlogInfo>
                          <BlogTitle>{node.title}</BlogTitle>
                          <BlogExcerpt>It's hard to find long lasting, quality docks. Jakes on the Lakes provides many services including dock building. We offer cedar float docks, poly float docks, Cedar-Poly float dock hybrid docks, and pier docks. Whether you need to build, install, or replace, Jakes on the Lakes is here for your summer 2021 dock needs.</BlogExcerpt>
                          <ReadMore to={`${node.fields.slug}`}>Read More	&gt;</ReadMore>
                        </BlogInfo>
                        <Goto to={`${node.fields.slug}`}>
                          <div>Read More</div>
                          <Arrow src={ArrowImg}/>
                        </Goto>
                      </BlogCard>
                    )
                })}
        </Content>
        <ContactForm data={this.props.data} />
      </Layout>
    )
  }
}

export const query = graphql`
    query {
      allNodeArticle (filter: {relationships: {og_audience: {elemMatch: {title: {eq: "jakeonthelakes.com"}}}}}, sort: {fields: created, order: DESC}) {
        edges {
          node {
            nid: drupal_internal__nid
            title
            body {
              processed
            }
            fields {
              slug
            }
            relationships {
              field_featured_image {
                relationships {
                  field_media_image {
                    publicUrl
                  }
                }
              }
            }
          }
        }
      }
      webformWebform(drupal_internal__id: { eq: "9gk5ucvtcrek9dgqtqq28a8fkvvhkdb2" }) {
        drupal_internal__id
        elements {
            name
            type
            attributes {
              name
              value
            }
        }
      }
    }
    `